@import "~antd/dist/antd.css";

body {
  font-family: Helvetica, Arial, san-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  font-weight: 600;
}

header {
  padding: 1em;
}

.view {
  padding-top: 0px;
  display: grid;
  grid-template:
    "item-section item-section"
    "item-title item-title"
    "item-description item-description"
    "larger-photo larger-diagram"
    "item-photo item-diagram"
    "item-labels item-labels";
  padding-bottom: 0px;
  /* border-bottom: 1px solid gray; */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto auto auto 1fr;
  grid-column-gap: 2rem;
}

@media only screen and (min-width: 600px) {
  body {
    padding: 0.5em;
  }

  h1 {
    font-size: 32px;
  }
  .view .labels {
    columns: 2;
  }
  .view .title {
    font-size: 28px;
  }

  .view .section,
  .view .section Button {
    font-size: 16px;
    font-weight: normal;
    margin-top: 0px;
    padding: 0px;
  }

  .view .description {
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .view {
    padding-top: 0px;
    display: grid;
    grid-template:
      "item-section"
      "item-title"
      "item-description"
      "larger-photo"
      "item-photo"
      "larger-diagram"
      "item-diagram"
      "item-labels";
    padding-bottom: 0px;
    /* border-bottom: 1px solid gray; */
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto auto 1fr;
  }

  h1 {
    font-size: 28px;
  }
  .view .title {
    font-size: 22px;
  }

  .view .section,
  .view .section Button {
    font-size: 14px;
    font-weight: normal;
    padding: 0px;
  }

  .view .description {
    font-size: 14px;
  }
}

.view .title .label,
.view .section .label,
.view .topic .label,
.view .description .label {
  display: none;
}

.view .section {
  grid-area: item-section;
  padding-bottom: 6px;
}

.view .title {
  grid-area: item-title;
  font-weight: normal;
  padding-bottom: 0px;
  padding-top: 0px;
  line-height: 1.2em;
}

.view .topic {
  grid-area: item-topic;
  padding-bottom: 2px;
  line-height: 1.2em;
}

.view .description {
  grid-area: item-description;
  padding-bottom: 16px;
  padding-top: 20px;
  line-height: 1.2em;
}

.view .diagram {
  grid-area: item-diagram;
  justify-self: center;
  align-self: center;
}

.view .photo {
  grid-area: item-photo;
  justify-self: center;
  align-self: center;
}

.view .diagram .iiz__img {
  object-fit: scale-down;
  max-height: 512px;
  width: 100%;
}

.view .diagram .iiz__img {
  max-height: 512px;
  object-fit: scale-down;
  width: 100%;
}

.view .labels {
  grid-area: item-labels;
  font-size: 14px;
  padding-left: 0.5em;
  padding-top: 12px;
}

.view .viewid {
  grid-area: item-viewid;
  font-size: 18px;
  font-weight: bold;
}

.view .labels {
  line-height: 1.2em;
}
.view .labels * div {
  margin-bottom: 4px;
}

.view .larger-photo {
  grid-area: larger-photo;
  margin-left: auto;
  margin-bottom: 4px;
  font-style: italic;
}

.view .larger-diagram {
  grid-area: larger-diagram;
  margin-left: auto;
  margin-bottom: 4px;
  font-style: italic;
}

h1 {
  margin-bottom: 10px;
  line-height: 1.2em;
  font-weight: normal;
}

.license {
  margin-left: 1em;
  margin-bottom: 18px;
  line-height: 1.4em;
  font-style: oblique;
  font-size: 14px;
  color: gray80;
}

.search-count {
  font-size: 16px;
  margin-left: 0.1em;
  font-style: oblique;
  padding-top: 0.5em;
  color: rgb(83, 83, 83);
}

.pageControls {
  display: flex;
  padding-bottom: 2em;
  font-size: 16px;
}
.pageControls .ant-pagination {
  align-self: center;
  justify-self: center;
  margin: auto;
}

.navBar {
  width: 100%;
  height: 48px;
  background-color: #eeeeee;
  padding-top: 10px;
}
.navBar .oa {
  height: 30px;
  margin-left: 10px;
  margin-top: -6px;
}
.navBar .oatext {
  font-size: 18px;
  padding-left: 10px;
  color: black;
}
footer {
  padding-bottom: 2em;
}
.spinnerdiv {
  width: 100%;
  margin-top: 5em;
  margin-bottom: 5em;
}
.spinnerdiv .ant-space-item {
  margin-left: auto;
  margin-right: auto;
}
.viewcard > .ant-card-body {
  padding-top: 10px;
}
